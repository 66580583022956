
import { Component, Vue, Provide } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import Loader from "@/components/widgets/common/Loader.vue"
import ScopeLibraryTagBar from "@/components/scopeLibrary/ScopeLibraryTagBar.vue"
import { ScopeServiceTagsQuery } from "@/gql"
import { NetworkStatus } from "@apollo/client/core"

@Component({
  components: {
    AppPage,
    ScopeLibraryTagBar,
    Loader,
  },

  apollo: {
    services: {
      query: ScopeServiceTagsQuery,

      result(result) {
        if (result.networkStatus === NetworkStatus.ready) {
          this.loadingServices = false
          this.loadingError = false
        }
      },
      error(error) {
        if (error.graphQLErrors) {
          this.loadingServices = false
          this.loadingError = true
        } else if (error.networkError) {
          this.networkError = true
        }
      },
      update(data) {
        return data?.scopeServices || []
      },
    },
  },
})
export default class Tags extends Vue {
  loadingServices = true
  loadingError = false
  networkError = false

  @Provide()
  get policy() {
    return {
      canManageScopeLibrary: this.can("manage_scope_library"),
    }
  }
}
